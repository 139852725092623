@import './imports/global';

/* Layout */
.aui-progress-tracker {
    display: table;
    font-size: 12px;
    margin: 10px 0 0;
    padding: 0;
    table-layout: fixed;
    word-wrap: break-word; /* doesn't work in IE */
}
.aui-progress-tracker:first-child {
    margin-top: 0;
}
.aui-progress-tracker-step {
    box-sizing: border-box;
    display: table-cell;
    padding: 0 10px;
    max-width: 140px;
    min-width: 80px;
    text-align: center;
}

/* Progress Bar */
.aui-progress-tracker-step > span,
.aui-progress-tracker-step > a {
    #aui.typography.h400();
    display: block;
    outline: none;
    padding-top: 25px;
    position: relative;
}
.aui-progress-tracker-step > span:before,
.aui-progress-tracker-step > a:before {
    background: @aui-progress-tracker-current-step-color;
    border-radius: 100%;
    content: "";
    height: 8px;
    left: 50%;
    margin-left: -5px;
    position: absolute;
    top: 4px;
    width: 8px;
}
.aui-progress-tracker-step + .aui-progress-tracker-step > span:after,
.aui-progress-tracker-step + .aui-progress-tracker-step > a:after {
    background: @aui-progress-tracker-current-step-color;
    border: solid @aui-progress-tracker-container-color;
    border-width: 2px 0;
    box-shadow: -5px 0 0 -2px @aui-progress-tracker-current-step-color, 5px 0 0 -2px @aui-progress-tracker-current-step-color;
    content: "";
    height: 8px;
    left: -50%;
    margin-left: -17px; /* touches right edge of previous dot */
    margin-right: 4px; /* touches left edge of this dot */
    position: absolute;
    right: 50%;
    top: 2px;
}
.aui-progress-tracker-step-current>span {
    color: @aui-progress-tracker-current-step-text-color;
}
.aui-progress-tracker-step-current ~ .aui-progress-tracker-step {
    color: @aui-progress-tracker-step-text-color;
}
.aui-progress-tracker-step-current ~ .aui-progress-tracker-step > span:before,
.aui-progress-tracker-step-current ~ .aui-progress-tracker-step > a:before {
    background-color: @aui-progress-tracker-step-color;
}
.aui-progress-tracker-step-current ~ .aui-progress-tracker-step > span:after,
.aui-progress-tracker-step-current ~ .aui-progress-tracker-step > a:after {
    background-color: transparent;
    border: none;
    box-shadow: none;
}

/* Interaction wih page layout */
.aui-page-header-actions .aui-progress-tracker {
    float: right;
}
