@aui-old-icon-font-family: "Atlassian Icons";
@aui-old-icon-font-file: "atlassian-icons";
@aui-old-icon-font-id: "atlassian-icons";

@aui-icon-font-family: "Adgs Icons";
@aui-icon-font-file: "adgs-icons";
@aui-icon-font-id: "adgs-icons";

// glyphs
@aui-glyph-check: "\f194";
@aui-glyph-check-circle: "\f104";
@aui-glyph-chevron-down: "\f15b";
@aui-glyph-close: "\f11b";
@aui-glyph-exclamation-triangle: "\f1b3";
@aui-glyph-exclamation-circle: "\f15a";
@aui-glyph-info-circle: "\f16f";
@aui-glyph-search: "\f18c";
@aui-glyph-search-small: "\f18d";
@aui-glyph-radio: "\f500";
@aui-glyph-remove: "\f188";
