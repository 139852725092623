// Why are we defining our own mixins when some already exist in Atlaskit? A few reasons:
//
// 1) Atlaskit's use the pure ADG3 proportions, which have different line heights and letter-spacing than we are taking on right now.
// 2) Atlaskit's include margin info, which we don't want.
//
// Even if they were exactly the same, we'd probably create our mixins by extending theirs in this file.
// The adapter pattern is generally useful in shielding us from external change by reducing its scope of impact to a single place.

#aui {
    .typography {
        // First-level heading (h1) equivalent
        .h800() {
            font-size: @aui-heading-xxlarge-font-size;
            font-weight: @aui-heading-xxlarge-font-weight;
            line-height: @aui-heading-xxlarge-line-height;
            letter-spacing: @aui-heading-xxlarge-letter-spacing;
            text-transform: @aui-heading-xxlarge-text-transform;
        }
        // Second-level heading (h2) equivalent
        .h700() {
            font-size: @aui-heading-xlarge-font-size;
            font-weight: @aui-heading-xlarge-font-weight;
            line-height: @aui-heading-xlarge-line-height;
            letter-spacing: @aui-heading-xlarge-letter-spacing;
            text-transform: @aui-heading-xlarge-text-transform;
        }
        // Third-level heading (h3) equivalent
        .h600() {
            font-size: @aui-heading-large-font-size;
            font-weight: @aui-heading-large-font-weight;
            line-height: @aui-heading-large-line-height;
            letter-spacing: @aui-heading-large-letter-spacing;
            text-transform: @aui-heading-large-text-transform;
        }
        // Fourth-level heading (h4) equivalent
        .h500() {
            font-size: @aui-heading-medium-font-size;
            font-weight: @aui-heading-medium-font-weight;
            line-height: @aui-heading-medium-line-height;
            letter-spacing: @aui-heading-medium-letter-spacing;
            text-transform: @aui-heading-medium-text-transform;
        }
        // Fifth-level heading (h5) equivalent
        .h400() {
            font-size: @aui-heading-small-font-size;
            font-weight: @aui-heading-small-font-weight;
            line-height: @aui-heading-small-line-height;
            letter-spacing: @aui-heading-small-letter-spacing;
            text-transform: @aui-heading-small-text-transform;
        }
        // Sixth-level heading (h6) equivalent
        .h300() {
            font-size: @aui-heading-xsmall-font-size;
            font-weight: @aui-heading-xsmall-font-weight;
            line-height: @aui-heading-xsmall-line-height;
            letter-spacing: @aui-heading-xsmall-letter-spacing;
            text-transform: @aui-heading-xsmall-text-transform;
        }
    }
}
